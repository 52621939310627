<template>
  <div>
    <el-card>
      <div class="stallName">
        摊位号
        <el-input
          v-model="queryInfo.stallName"
          placeholder="请输入摊位号"
          clearable
          @clear="handleChangeTime"
          @change="handleChangeTime"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="handleChangeTime"
          ></el-button>
        </el-input>
      </div>
      <div class="shopName">
        店铺名称
        <el-input
          v-model="queryInfo.merchantsName"
          placeholder="请输入店铺名称"
          clearable
          @clear="handleChangeTime"
          @change="handleChangeTime"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getShopSalesList"
          ></el-button>
        </el-input>
      </div>
      <!-- 交易时间 -->
      <div class="deal-time">
        <span>起止时间：</span>
        <el-date-picker
          v-model="value1"
          type="daterange"
          value-format="yyyy-MM-dd"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          clearable
          @clear="handleChangeTime"
          @change="handleChangeTime"
          unlink-panels
        >
        </el-date-picker>
      </div>
      <el-table
        :data="shopSalesList"
        border
        stripe
        style="width: 100%"
        tooltip-effect="light"
        highlight-current-row
      >
        <el-table-column
          label="摊位号"
          align="center"
          prop="stallName"
        ></el-table-column>
        <el-table-column
          label="店铺名称"
          align="center"
          prop="merchantsName"
        ></el-table-column>
        <el-table-column
          width="250"
          align="center"
          prop="salesTotal"
        >
          <template
            slot="header"
            slot-scope="scope"
          >
            <span @click="handleEdit(scope.$index)">总销售额（元）</span>
            <i
              class="el-icon-caret-bottom"
              @click="handleEdit(scope.$index)"
            ></i>
          </template>
        </el-table-column>
        <el-table-column
          label=""
          width="250"
          align="center"
          prop="orderNumberTotal"
        >
          <template
            slot="header"
            slot-scope="scope"
          >
            <span @click="handleEdit(scope.$index)">总订单数</span>
            <i
              class="el-icon-caret-bottom"
              @click="handleEdit(scope.$index)"
            ></i>
          </template>
        </el-table-column>
        <el-table-column
          label=""
          width="250"
          align="center"
          prop="guestUnitPrice"
        >
          <template
            slot="header"
            slot-scope="scope"
          >
            <span @click="handleEdit(scope.$index)">客单价（元）</span>
            <i
              class="el-icon-caret-bottom"
              @click="handleEdit(scope.$index)"
            ></i>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNo"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="queryInfo.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  data () {
    return {
      queryInfo: {
        limit: 15,
        pageNo: 1,
        marketId: '',
        sortType: 0,
        stallName: '',
        merchantsName: '',
        minCreateTime: '',
        maxCreateTime: ''
      },
      total: 0,
      value1: [moment(new Date()).format('YYYY-MM-DD'), moment(new Date()).format('YYYY-MM-DD')],
      shopSalesList: []
    }
  },
  created () {
    this.getShopSalesList()
  },
  methods: {
    async getShopSalesList () {
      this.queryInfo.marketId = window.sessionStorage.getItem('currentMarketId')
      const [min, max] = this.value1 || [undefined, undefined]
      this.queryInfo.minCreateTime = min
      this.queryInfo.maxCreateTime = max
      const { data: res } = await this.$http.get('orderMain/merchantSales', {
        params: this.queryInfo
      })
      if (res.code !== 0) {
        return this.$message.error(res.message)
      }
      this.shopSalesList = res.data.data.map(item => ({
        ...item,
        salesTotal: (item.salesTotal / 100.0).toFixed(2),
        guestUnitPrice: (item.guestUnitPrice / 100.0).toFixed(2)
      }))
      this.total = res.data.total
    },
    // limit变化，调用列表接口
    handleSizeChange (newSize) {
      this.queryInfo.limit = newSize
      this.getShopSalesList()
    },
    // 页码变化，调用接口
    handleCurrentChange (newPage) {
      this.queryInfo.pageNo = newPage
      this.getShopSalesList()
    },
    handleEdit (index) {
      // console.log(index)
      if (index === 2) {
        this.queryInfo.sortType = 0
        this.getShopSalesList()
      } else if (index === 3) {
        this.queryInfo.sortType = 1
        this.getShopSalesList()
      } else if (index === 4) {
        this.queryInfo.sortType = 2
        this.getShopSalesList()
      }
    },
    handleChangeTime () {
      this.queryInfo.pageNo = 1
      this.getShopSalesList()
    }

  }
}
</script>

<style lang="less" scoped>
.stallName {
  width: 300px;
  // border: 1px solid #000;
  .el-input {
    width: 240px;
  }
}
.shopName {
  width: 300px;
  margin-left: 310px;
  margin-top: -40px;
  .el-input {
    width: 220px;
  }
}
.deal-time {
  width: 430px;
  // border: 1px solid #000;
  margin-left: 620px;
  margin-top: -40px;
  .el-date-picker {
    width: 200px;
  }
}
</style>
